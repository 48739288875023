import React from "react";
import styled from "styled-components";

const VideoContainer = styled.div`
	width: 100%;
	height: 0;
	padding-top: 56.25%;
	position: relative;
`;

const StyledVideo = styled.iframe`
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	overflow: hidden;
	border: 0;
`;

export const VideoPreview = ({ videoSrcUrl }) => {
	const videoStrings = videoSrcUrl.split("/");
	const videoId = videoStrings[videoStrings.length - 1];
	// console.log(videoId);
	return null;
};

const Video = ({ videoSrcUrl, videoTitle, ...props }) => (
	<VideoContainer>
		<StyledVideo
			src={videoSrcUrl}
			width="640"
			height="268"
			frameborder="0"
			allow="autoplay; fullscreen; picture-in-picture"
			allowfullscreen
		></StyledVideo>
	</VideoContainer>
);

export default Video;
