import React, { useState } from "react";
import { format } from "date-fns";
import { imageUrlFor } from "../lib/image-url";
import { buildImageObj } from "../lib/helpers";
import styled from "styled-components";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const StyledCarouselWrapper = styled.div`
	opacity: ${props => props.show ? '1' : '0'};
	pointer-events: ${props => props.show ? 'all' : 'none'};

	> a {
		position: fixed;
		top: 50px;
		right: 60px;
		text-decoration: none;
		z-index: 11;
		color: black;
		font-size: 40px;
		font-weight: 200;
		@media (max-width: 1140px) {
			top: 20px;
			right: 20px;
		}
	}
`

const StyledCarousel = styled(Carousel)`
	background: white;
	position: fixed;
	padding: 40px 120px 80px;
	top: 40px;
	left: 40px;
	right: 40px;
	bottom: 40px;

  @media (max-width: 1140px) {
		padding-left: 20px;
		padding-right: 20px;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
  }
	border: 1px solid;
	z-index: 10;

	.slide {
		background: transparent;
		color: black;
    max-height: 70%;
		max-width: 65vw;
		padding-bottom: 40px;
		@media (max-width: 1140px) {
			padding-left: 30px;
			padding-right: 30px;
		}


		div {
			// display: flex;
			// flex-direction: column;
		}

		.img {
			max-height: 70vh;
			overflow: hidden;

			img {
				object-fit: contain;
				height: calc(70vh - 40px);
				width: 100%;
			}
		}

		.caption {
			// padding-top: 30px;
		}
	}
	.control-dots {
		display: none;
	}
	.carousel.carousel-slider .control-arrow {
		position: fixed;
		opacity: 11;
		margin-right: 80px;

		@media (max-width: 1140px) {
			margin-right: 0px;
		}

		&:before {
			width: 16px;
			height: 16px;
			transform: rotate(45deg);
			border: 0;
			border-top: 3px solid black;
			border-right: 3px solid black;
		}

		&:hover {
			background: transparent;
		}

		&.control-disabled {
			display: block;
			opacity: 0.75;
		}
		&.control-prev {
			margin-left: 80px;
			margin-right: 0;
			@media (max-width: 1140px) {
				margin-left: 0px;
			}

			&:before {
				transform: rotate(-135deg);
			}
		}
	}
	.carousel-status {
		position: fixed;
		top: auto;
		font-family: serif;
		font-size: inherit;
		bottom: 60px;
		right: auto;
		left: 50%;
		transform: translateX(-50%);
		color: black;
		text-shadow: none;
		@media (max-width: 1140px) {
			display: none;
		}
	}
`
const StyledStandardImg = styled.img`
	box-sizing: border-box;
	cursor: pointer;
	display: block;
	padding-bottom: 30px;
	max-height: 100%;
	width: 100%;
	mix-blend-mode: multiply;
`;

/* Standalone image component with no modal */
export const StandardImg = ({ mainImage }) => {
	const aspectRatio = mainImage.asset.metadata.dimensions.aspectRatio;
	return (
		<StyledStandardImg
			src={imageUrlFor(buildImageObj(mainImage)).url()}
			alt={mainImage.alt ? mainImage.alt : ""}
			className={aspectRatio < 1 ? "portrait" : "landscape"}
		/>
	);
};

/* Modal that expands image on click. Also an example of using the Other Images field from sanity (remove example lines once slideshow is implemented) */
const ImageModal = ({ mainImage, size, modal, otherImages, ...props }) => {
	const [showGallery, setShowGallery] = useState(false);

	const aspectRatio = mainImage.asset.metadata.dimensions.aspectRatio;
	// Example usage of otherImages
	let currentImage = mainImage;
	let author = props.members[0] ? props.members[0].person.name : 'Unknown';

	return (
		<>
			<div
				onClick={e => setShowGallery(true)}
			>
				<StyledStandardImg
					src={imageUrlFor(buildImageObj(currentImage)).url()}
					alt={currentImage.alt ? currentImage.alt : ""}
				/>
			</div>

			<StyledCarouselWrapper show={showGallery}>
				<a
					href="#"
					onClick={e => setShowGallery(false) }
				>
					&times;
				</a>
				<StyledCarousel>
					<div>
						<div class="img">
							<StyledStandardImg
								src={imageUrlFor(buildImageObj(currentImage)).url()}
								alt={currentImage.alt ? currentImage.alt : ""}
							/>
						</div>
						<div className="caption">
							<p>
								<em>{props.title}</em>,
								Naarm/Melbourne: Victorian College of the Arts, {format(new Date(props.publishedAt), "YYYY")}
							</p>
						</div>
					</div>
					{otherImages && otherImages.map(image => {
						return (
							<div>
								<div class="img">
									<StyledStandardImg
										src={imageUrlFor(buildImageObj(image)).url()}
									/>
								</div>
								<div className="caption">
									<p>
										<em>{props.title}</em>,
										Naarm/Melbourne: Victorian College of the Arts, {new Date(props.publishedAt).getFullYear()}
									</p>
								</div>
							</div>
						)
					})}
				</StyledCarousel>
			</StyledCarouselWrapper>
		</>
	);
};

export default ImageModal;
